/** @jsx jsx */
import { jsx } from 'theme-ui';
import { Container, Box } from 'theme-ui';
import TextFeature from 'components/text-feature';
// import Img from 'gatsby-image';
// import { useStaticQuery, graphql } from 'gatsby';

const data = [
    // {        
    //     title: ' REGULAMIN ',
    //     subTitle: '',
    //     description: '',
    //     btnName: 'PDF',
    //     btnURL: 'http://archiwum.pttk.pl/pttk/odznaki/ro_otp.pdf',
    // },
    {        
        title: 'REGULAMIN',
        underTitle: 'ODZNAKI TURYSTYCZNO-KRAJOZNAWCZEJ',
    },
    {        
        underTitle: ' „MŁAWSKI SZLAK TURYSTYCZNY” ',
        description: 'W związku z odnowieniem i przywróceniem do funkcjonowania czerwonego szlaku turystycznego w okolicach Mławy, Zarząd Oddziału PTTK „Mazowsze” z siedzibą w Warszawie ustanowił Odznakę Turystyczno-Krajoznawczą „Mławski Szlak Turystyczny”. Odznakę zdobywa się przez zwiedzenie obiektów położonych na terenie miasta Mława, gmin Iłowo-Osada, Lipowiec Kościelny, Szydłowo i Wieczfnia Kościelna przy i w pobliżu szlaku turystycznego o tej nazwie w kształcie nieregularnej pętli oznakowanego kolorem czerwonym oraz szlaku dojściowego oznaczonego kolorem czerwonym. Lista obiektów do zwiedzenia obejmuje łącznie 150 obiektów punktowanych. Są to obiekty związane bezpośrednio lub pośrednio z walkami toczonymi we wrześniu 1939 r., a także inne interesujące obiekty krajoznawcze. Celem odznaki jest zachęcenie dzieci i młodzieży oraz dorosłych do uprawiania turystyki i do korzystania ze szlaku, rozbudzenie zainteresowania lokalną historią, a w szczególności wydarzeniami związanymi z obroną pozycji mławskiej w 1939 r. i miejscami upamiętnienia tych wydarzeń.',      
    },
    {        
      underTitle: 'WARUNKI ZDOBYWANIA ODZNAKI',
      description: '1. Odznaka jest wirtualna, trzystopniowa: w stopniach brązowym, srebrnym i złotym o jednakowym wzorze dla wszystkich stopni. Stopnie różnią się kolorem grafiki. Odznaka może być zdobywana przez osoby dorosłe oraz dzieci od 5. roku życia zwiedzające pod opieką dorosłych. Odznakę można zdobyć podczas wycieczek krajoznawczych zbiorowych lub indywidualnych odbywanych pieszo lub dowolnym środkiem transportu. Trasy wycieczek mogą obejmować dowolną liczbę obiektów i zwiedzać je można w dowolnej kolejności. Czas zdobywania poszczególnych stopni odznaki jest nieograniczony.',
    },
    {        
      description: '2. Aby zdobyć odznakę, należy zwiedzić następujące liczby obiektów punktowanych: \n\
      a) odznaka w stopniu brązowym – 50 obiektów, \n\
      b) odznaka w stopniu srebrnym – 50 nie zwiedzanych na odznakę w stopniu brązowym, \n\
      c) odznaka w stopniu złotym – 50 nie zwiedzanych na poprzednie stopnie odznaki.',
    },
    {        
      description: '3. Dokumentacja zwiedzanych obiektów dla potrzeb weryfikacji i przyznania odznak poszczególnych stopni jest prowadzona wyłącznie w formie elektronicznej przez przeznaczoną do tego celu aplikację o nazwie „Mławski Szlak Turystyczny”, którą można bezpłatnie pobrać przez Sklep Play albo App Store na swoje urządzenie elektroniczne (telefon komórkowy, tablet itp.). Linki do pobrania znajdują się m.in. pod adresem www.szlak.mlawa.pl. Aplikacja zapamiętuje i zlicza zwiedzane obiekty, a po zwiedzeniu liczby obiektów wymaganej dla zdobycia danego stopnia odznaki informuje o tym użytkownika.',
    },
    {        
      description: '4. Weryfikacja i przyznawanie odznak odbywa się bezpośrednio w aplikacji.',
    },
    {        
      description: '5. Odznaka została wykonana na zamówienie Urzędu Miasta Mlawa. ',
    },
    {        
      description: '6. Prawo interpretacji regulaminu przysługuje Oddziałowi PTTK „Mazowsze”. Zmiany w regulaminie wprowadza Zarząd Oddziału PTTK „Mazowsze” na wniosek lub po zasięgnięciu opinii Urzędu Miasta Mława.',
    },
    {        
      description: '7. Autorem regulaminu jest Mieczysław Żochowski.',
    },
  ];

  

export default function Terms() {


  return (
    <section id='terms' sx={{ variant: 'section.securePayment' }}>
      <Box sx={styles.bgOverlay} />
      <Container sx={styles.containerBox}>
       
        <Box sx={styles.contentBox}>
            {data.map((item) => (
                <TextFeature
                  subTitle={item.subTitle}
                  title={item.title}
                  underTitle={item.underTitle}
                  description={item.description}
                  btnName={item.btnName}
                  // null
                  btnURL={item.btnURL}
                  // null
                  // data-html="true"
                />
            ))}          
        </Box>

      </Container>
    </section>
  );
}

const styles = {
  bgOverlay: {
    position: 'absolute',
    top: 0,
    right: 0,
    height: '100%',
    zIndex: -1,
    width: [
      '100%',
      null,
      null,
      'calc(50% + 400px)',
      'calc(50% + 480px)',
      'calc(50% + 570px)',
      null,
      'calc(50% + 625px)',
    ],
    // backgroundImage: `url(${PaymentPattern})`,
    // backgroundRepeat: `no-repeat`,
    // backgroundPosition: 'top left',
    // backgroundSize: 'cover',
    // backgroundColor: 'white',
    // background: 'linear-gradient(-45deg, rgba(42,72,125, 0.3) 25%, transparent 25%, transparent 50%, rgba(42,72,125, 0.3) 50%, rgba(42,72,125, 0.3) 75%, transparent 75%, transparent)',
    // backgroundColor: 'primary',
  },
  containerBox: {
    mt: 10,
    display: 'flex',
    alignItems: 'flex-start',
    // justifyContent: 'space-between',
    flexWrap: ['wrap', null, null, 'nowrap'],
  },
  thumbnail: {
    mb: -2,
    ml: [-4, '-40px', null, null, 0],
    order: [2, null, null, 0],
    pr: [6, null, 7, 0],
    width: [
      'calc(100% + 20px)',
      'calc(100% + 40px)',
      null,
      370,
      460,
      570,
      null,
      680,
    ],
  },
  contentBox: {
    // width: ['100%', 420, 480, 380, 500, 570],
    width: '100%',
    mx: 'auto',
    flexShrink: 0,
    // textAlign: ['center', null, null, 'left'],
    textAlign: 'center',
    pt: [0, null, null, 4, '50px', null, 4, '80px'],
    pl: [0, null, null, 40, '90px'],
    pb: [7, null, null, 9],
    pr: [0, null, null, null, null, 6],
  },
};
